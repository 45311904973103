/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Hoy he dicho a ver que me instalo y me a venido a la cabeza la imagen de uno de los programas mas curiosos que a creado el hombre. Google Earth, ya van dos cosas seguidas que me instalo de google… es casualidad no que haga propaganda ni nada parecido :-)."), "\n", React.createElement(_components.p, null, "Para instalarse Google Earth hay que bajarse un archivo .bin que encontrareis ", React.createElement(_components.a, {
    href: "http://earth.google.es/download-earth.html"
  }, "aqui"), " Luego abrimos una consola y con el comando cd vamos a la carpeta donde hayamos descargado el archivo. Y escribimos:"), "\n", React.createElement(_components.p, null, "$ ", React.createElement(_components.strong, null, "sh GoogleEarthLinux.bin")), "\n", React.createElement(_components.p, null, "Ya lo tenemos instalado ahora a disfrutar."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/02/esculturaquart.jpg",
    title: "esculturaquart.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 128px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAMC/9oADAMBAAIQAxAAAAFteZpDpiYd/wD/xAAZEAEAAwEBAAAAAAAAAAAAAAABAAIDERP/2gAIAQEAAQUCSJK59PS3TWw12J//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARL/2gAIAQMBAT8Byj//xAAXEQADAQAAAAAAAAAAAAAAAAAAARIh/9oACAECAQE/AbHrP//EABgQAAMBAQAAAAAAAAAAAAAAAAEQIQAR/9oACAEBAAY/AnCVe7//xAAcEAACAgIDAAAAAAAAAAAAAAAAAREhMWFBUYH/2gAIAQEAAT8hHKTzjkm9Czt+mMKyPR//2gAMAwEAAgADAAAAEHTf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPxDmAGX/xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhMf/aAAgBAgEBPxCrweRn/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAITFBof/aAAgBAQABPxBzjjTCobkE8EI9h66hUiiuwGEZHilUYJ//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"esculturaquart.jpg\"\n        title=\"esculturaquart.jpg\"\n        src=\"/static/80c59334438649f8d05eee0d55091f53/474e0/esculturaquart.thumbnail.jpg\"\n        srcset=\"/static/80c59334438649f8d05eee0d55091f53/474e0/esculturaquart.thumbnail.jpg 128w\"\n        sizes=\"(max-width: 128px) 100vw, 128px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "Esta es la foto que he encontrado ahí de la plaza a la que da mi finca, a la derecha está el ayuntamiento, en el centro la escultura que representa a nuestro pueblo, el fanalet, y al fondo a la izquierda el casino que ahora mismo estan “restaurando”."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
